@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

.sr-only {
  position: absolute;
  overflow: hidden;
  width: rem(1);
  height: rem(1);
  padding: 0;
  border: 0;
  margin: rem(-1);
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@media (min-width: map-get($grid-breakpoints, sm)) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-flex {
    display: flexbox !important;
    display: flex !important;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
  .d-md-none {
    display: none !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: flex !important;
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-flex {
    display: flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.justify-content-center {
  justify-content: center !important;
  -ms-flex-pack: center !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.collapse:not(.show) {
  display: none;
}
