@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// fonts
// TODO : use 'next/font' when we upgrade to next 13
// https://nextjs.org/docs/pages/building-your-application/optimizing/fonts

@font-face {
  font-family: Rubik;
  font-weight: 300;
  src: local('Rubik Light'), local('Rubik-Light'),
    url('/fonts/Rubik-Light.ttf') format('truetype');
}

@font-face {
  font-family: Rubik;
  font-weight: 400;
  src: local('Rubik Regular'), local('Rubik-Regular'),
    url('/fonts/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Rubik;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'),
    url('/fonts/Rubik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: Rubik;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'),
    url('/fonts/Rubik-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Rubik;
  font-weight: 900;
  src: local('Rubik Black'), local('Rubik-Black'),
    url('/fonts/Rubik-Black.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 100;
  src: local('Lato Thin'), local('Lato-Thin'),
    url('/fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
    url('/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'),
    url('/fonts/Lato-Black.ttf') format('truetype');
}

// global styles here

html,
body {
  font-size: 14px;
  font-family: Rubik, Lato, sans-serif;
}

body {
  color: $brown;
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

address {
  margin-bottom: 0;
}

// Avoid iOS auto-zooming on inputs
// stylelint-disable-next-line media-feature-name-no-vendor-prefix
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @include mobile {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
}

.text-left {
  text-align: left !important;
}

h1 {
  font-size: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
